import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import Table from "react-bootstrap/Table";
import axiosMain from "../../http/axios/axios_main";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { moment } from "moment";
import useAuth from "../../hooks/useAuth";
import axiosNew from './../../http/axios/axios_new';


const Invoice_Genrate = () => {
  const { user, isAuthenticated } = useAuth();
  var converter = require('number-to-words');
  const { state } = useLocation("/invoice");
  const { presid } = state;
  const { apointid } = state;
  const { type_in } = state;

  const { invoiceid } = state;

  const [indetail, setindetail] = useState(false);
  const [pname, setpname] = useState(false);
  const [mr, setmr] = useState(false);
  const [age, setage] = useState(false);
  const [doctor, setdoctor] = useState(false);
  const [center, setcenter] = useState(false);
  const [bill, setbill] = useState(false);
  const [date_patient, setdate_patient] = useState(false);
  const [company, setcompany] = useState(false);
  const [mobile, setmobile] = useState(false);
  const [tabledata, settabledata] = useState(false);
  const [appoin_type, setappoin_type] = useState(false);
  const [puid, setpuid] = useState();

  const [discount_val, setdiscount_val] = useState(false);

  const [consulfee, setconsulfee] = useState(false);
  const [discdata, setdiscdata] = useState(0);

  const [discdatawords, setdiscdatawords] = useState(0);

  const getData = async () => {
    // const api = await axios.post("https://api.medyseva.com/api/vle/invoice/details", {

    const api = await axiosNew.post("/api/vle/invoice/details", {
      vle_id: user.id,
      appoinment_id: apointid,
      prescription_id: presid,
      invoice_id: invoiceid,
      invoice_type: type_in
    });
    console.log(api, 'newinvoice_ho gya data');

    if (api.data.status == 1) {


      setdiscdatawords(api.data.data.invoice.coupon_amount);
      setdiscdata(api.data.data.invoice);
      setpuid(api.data.data.appointment_data.puid);
      setpname(api.data.data.appointment_data.name);
      setmr(api.data.data.appointment_data.mr_number);
      setage(api.data.data.appointment_data.age);
      setconsulfee(api.data.data.consultation_fee);

      setdoctor(api.data.data.appointment_data.dr_name);
      setcenter(api.data.data.appointment_data.chamber_name);
      // setcompany(api.data.data.appointment_data.created_at);

      setbill(api.data.data.invoice_number);
      setdate_patient(api.data.data.appointment_data.date);
      setmobile(api.data.data.appointment_data.mobile);
      settabledata(api.data.data.pre_investigation);
      setdiscount_val(api.data.data.appointment_data.discount_value);

      setappoin_type(api.data.data.appointment_data.appointment_type);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  var consultaionfees = consulfee;

  if (type_in == 'user_invoice') {

    totalinv = consultaionfees;

  } else {
    var totalinv = 0;
    {
      tabledata &&
        tabledata.map((items, index) => {
          totalinv += items.price;
          totalinv = totalinv;
        })
    };
  }

   function printDiv() {
        var printContents = document.getElementById("printLayout").innerHTML;
          var printContents = document.getElementById("printLayout").innerHTML;
     var originalContents = document.body.innerHTML;

     document.body.innerHTML = printContents;

     window.print();

     document.body.innerHTML = originalContents;
    }
  // const handlecreateinvoice = async () => {
  //   // console.log(data,'pid');
  //   // const apis = await axios.post("https://api.medyseva.com/api/vle/invoice/create", {

  //   const apis = await axios.post("http://3.7.66.101/api/vle/invoice/create", {

  //     vle_id: user.id,
  //     appoinment_id: apointid,
  //     prescription_id: presid,
  //   });
  //   console.log(apis.data.msg, "data aa gaya");
  //   // setinvoice(apis.data.msg);
  //   if (apis.data.status == 1) {
  //     toast.success("Invoice Created Successfully");

  //     // window.location.reload();
  //     // setTimeout(getData(), 3000);
  //   }
  //   window.location.reload();

  // };

  let totalvaluestr = converter.toWords((+totalinv - discdatawords));
  let tvstr = totalvaluestr.toUpperCase();
  const  printPageArea = async (areaID)=>{
    var printContent = document.getElementById(areaID).innerHTML;
    var originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  }

  return (
    <div>
      <Dashboard_layout>
        <ToastContainer />
        <div className="content-top-padding">
        <button className="btn btn-primary mx-4" style={{left: "40px", display: "block", position: "relative"}} type="button" onClick={(e)=>printPageArea('printableArea')}>Print Invoice</button>
          <div 
  	      id="printableArea"
            style={{
              border: "1px solid #ddd",
              width: "900px",
              margin: "0 auto",
              "font-family": "sans-serif",
              "margin-top": "20px",
              padding: "30px 20px",
            }}
          >

            <div style={{ padding: "30px 20px" }}  id="printLayout">
              <table style={{ width: "100%", padding: "20px 0" }}>
                <tbody>
                  <tr>
                    <td width="20%" style={{ padding: "10px" }}>
                     

                      {user.country == "india" ? (
                        <img
                        className="img-fluid chamber-img1"
                          src="assets\img\logo\logo.png"
                        />

                      ) : (
                        <img
                        className="img-fluid chamber-img1"
                        src="assets\img\logo\logo.png"
                        />
                      )}
                    </td>
                    <td
                      width="60%"
                      style={{
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        padding: "10px",
                      }}
                    >
                      <h5
                        style={{
                          "margin-bottom": "5px",
                          "margin-top": "0",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        3rd floor, Gayway House, PP Compound, Mahatma Gandhi Main Rd, Hindpiri, Ranchi, Jharkhand 834001  <br></br>Phone: +91 93415 49721, +91 75669 75666 <br></br>Mail To:medyseva@vedantpay.com              
                      </h5>
                      <h4
                        style={{
                          "margin-bottom": "5px",
                          "margin-top": "0",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {/* manishapatidar1234@questglt.org */}
                      </h4>
                    </td>
                    <td
                      width="12%"
                      style={{ "vertical-align": "bottom", padding: "10px" }}
                    >
                      <h5
                        style={{
                          "margin-bottom": "5px",
                          "margin-top": "0",
                          "-webkit-text-align": "right",
                          "text-align": "right",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                        OPD Billing
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="" style={{ borderTop: "1px solid #ddd" }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td width="50%" style={{ padding: "10px" }}>
                      <table>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Patient Name:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{pname}</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            MR No.:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{mr}</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Gender/ Age:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{age}</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Ref. Doctor:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{doctor}</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Center Name:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{center}</span>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td width="50%" style={{ padding: "10px" }}>
                      <table>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Bill No.:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{bill}</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Date:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{date_patient}</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Ref. Company:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>SELF</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="40%"
                            style={{
                              fontSize: "14px",
                              padding: "5px",
                              fontWeight: "600",
                            }}
                          >
                            Mobile No.:
                          </td>
                          <td
                            width="60%"
                            style={{ fontSize: "14px", padding: "5px" }}
                          >
                            <span>{mobile}</span>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="for table-box">
              <Table stripped bordered hover size="sm">
                <thead style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                  <tr>
                    <th width="10%" style={{ color: "#000" }}>
                      S.No
                    </th>
                    <th width="25%" style={{ color: "#000" }}>
                      {" "}
                      Service Name
                    </th>
                    <th width="25%" style={{ color: "#000" }}>
                      QTY
                    </th>
                    <th width="25%" style={{ color: "#000" }}>
                      {" "}
                      Rate
                    </th>
                    <th width="20%" style={{ color: "#000" }}>
                      Total Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {type_in == 'user_invoice' ? (

                    <tr>
                      <td>1</td>
                      <td>Consultaion Fee</td>
                      <td>1</td>
                      <td>{consulfee}</td>
                      <td>{consulfee}</td>
                    </tr>

                  ) : ''}



                  {type_in == 'lab_invoice' ? tabledata &&
                    tabledata.map((items, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items.name}</td>
                          <td>1</td>
                          <td>{items.price}</td>
                          <td>{1 * items.price}</td>
                        </tr>
                      );
                    }) : ''}

                  <tr>
                    {/* <td>{index + 1}</td>
                    <td>{items.name}</td>
                    <td>{items.price ==null ?'0' :items.price}</td>
                    <td>{items.discount==null ? '0' : items.price}</td>
                    <td>{items.price ==null ?'0' :items.price}</td> */}
                  </tr>
                  {/* ) */}
                  {/* })} */}
                </tbody>
              </Table>
              <table
                style={{
                  width: "40%",
                  marginLeft: "auto",
                  fontSize: "14px",
                  marginTop: "20px",
                  borderCollapse: "collapse",
                }}
              >
                <tbody style={{ borderBottom: "1px solid #eee" }}>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <b>Discount</b>
                    </td>
                    <td style={{ padding: "5px" }}>:</td>
                    <td style={{ padding: "5px" }}>{discdata.coupon_amount}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <b>Total</b>
                    </td>
                    <td style={{ padding: "5px" }}>:</td>
                    <td style={{ padding: "5px" }}>
                      <b>Rs. {totalinv - discdata.coupon_amount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      {/* <button
                        style={{
                          backgroundColor: "#053479",
                          color: "#fff",
                          padding: "8px 10px",
                          fontSize: "14px",
                          borderRadius: "5px",
                          border: "none",
                        }}
                      >
                        Apply Coupon
                      </button> */}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style={{
                  width: "50%",
                  fontSize: "14px",
                  marginTop: "20px",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <b>Net Amount In Words</b>
                    </td>
                    <td style={{ padding: "5px" }}>:</td>
                    <td style={{ padding: "5px" }}>
                      <b>{tvstr}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                className=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >

                {/* {puid != null ? (
                  <div></div>
                ) : (
                  <div>
                    <button
                      style={{
                        backgroundColor: "#053479",
                        color: "#fff",
                        padding: "8px 10px",
                        fontSize: "14px",
                        borderRadius: "5px",
                        border: "none",

                      }}
                      onClick={(e) => handlecreateinvoice()}
                    >
                      Cash
                    </button>
                  </div>
                )} */}

                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                  <b>For Vedant Medyseva</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard_layout>
    </div>
  );
};

export default Invoice_Genrate;
