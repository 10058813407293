import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import axiosMain from "./../../http/axios/axios_main";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import ReactPaginate from "react-paginate";
import axiosNew from './../../http/axios/axios_new';
import helpers from "../../Helper/CryptoHelper";

const Invoice = () => {
  const navigate = useNavigate();

  const { user, isAuthenticated } = useAuth();
  // http://3.7.66.101/api/vle/invoice

  const [invoice, setinvoice] = useState({
    isloading: false,
    data: [],
    error: "",
  });
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 20;
  const pageVisited = pageNumber * usersPerPage;
  // const [precription_id, setprecription_id] = useState('');
  const [inputdata, setInputdata] = useState({
    pagecount: 30,
  });
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    console.log("in-d")

    try {

      setinvoice((prev) => {
        return {
          ...prev,
          isloading: true
        }
      });
      // const api = await axios.post("https://api.medyseva.com/api/vle-invoice", {

      const api = await axiosNew.post("/api/vle-invoice", helpers.encrypt(JSON.stringify({

        user_id: user.id,
        limit: 400,
      })));
      api.data = helpers.decrypt(JSON.stringify(api.data));
      console.log(api.data.original.data, "invoicedatayeppps");
      // setinvoice(api.data.data);

      setinvoice({
        isloading: false,
        data: api.data.original.data.slice(0, 1000),
        error: "",
      })
    } catch (error) {

      setinvoice({
        isloading: false,
        data: [],
        error: error.data,
      })
    }


  };


  const handleAppointment = async (data) => {
    console.log(data, "uuttyyeeeeeeeeeeeee");
    // var prescripid = '';
    // data.prescription.map((items) => prescripid = items.id)
    // console.log(data.appointment_id,'dataprescriappointp');
    // navigate('/invoice-detail',{ state: { incid: data.invoice_id } });

    navigate("/invoice-detail", { state: { presid: data.prescription_id, apointid: data.appointment_id, type_in: data.invoice_type, invoiceid: data.invoice_id } });
  };

   
  const createinvoice = async (data) => {

    var prescripid_create = '';
    data.prescription.map((items) => prescripid_create = items.id)

    navigate("/createinvoice", { state: { prescriptionid: prescripid_create } });
  };
  const pageCount = Math.ceil(invoice.data.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);

  }

  const [inputdatacount, setinputdatacount] = useState({
    start: '',
    end: ''

  });

  const formdatacount = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputdatacount({ ...inputdatacount, [name]: value });
  };


  const downloadexcel = () => {

    if (inputdatacount.start && inputdatacount.end != '') {

      // axios.post("https://api.medyseva.com/api/vle/wallet/export", {

      axiosNew.post("/api/vle/invoice/export", {

        vle_id: user.id,
        start_date: inputdatacount.start,
        end_date: inputdatacount.end

      },
        {
          responseType: "blob"
        })
        .then(response => {
          let blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        });

    } else {

      toast.warn('Please enter Valid date');

    }


  }

  return (
    <div>
      <>
        <Dashboard_layout>
          <ToastContainer />
          {/* Begin Page Content */}
          <div className="content-top-padding">
            <section className="card-outer-box">
              <div className="inner-content">
                <form>
                  <div className="row">
                    <div className="col-md-4 mb-1">
                      <div className="input-group date" id="datepicker">
                        <input type="date" className="form-control" name="start" value={inputdatacount.start} onChange={formdatacount} id="date" />
                      </div>
                    </div>
                    <div className="col-md-4 mb-1">
                      <div className="input-group date" id="datepicker">
                        <input type="date" onChange={formdatacount} name="end" value={inputdatacount.end} className="form-control" id="date" />
                      </div>
                    </div>
                    <div className="col-md-4 mb-1">
                      <button type="button" className="btn btn-primary" onClick={downloadexcel}>
                        Export To Excel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary mt-2">
                  Invoices
                </h6>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-bordered vle-table"
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        <th>Patient Name</th>
                        <th>Invoice No</th>
                        <th>Type</th>

                        <th>Total Amount</th>
                        <th>TDS</th>
                        {/* <th>Received Amount</th> */}
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice.isloading && (
                        <tr>
                          <td
                            style={{ textAlign: "center", color: "red" }}
                            colspan="8"
                          >
                            Data loading
                          </td>
                        </tr>
                      )}
                      {!invoice.isloading && invoice.data &&
                        [...invoice.data]?.slice(pageVisited, pageVisited + usersPerPage).map((items, key) => {
                          return (
                            <tr>
                              <td>{items.patient != null ? items.patient.name : ''}</td>
                              <td>{items.invoice_number}</td>
                              <td>{items.invoice_type == 'user_invoice' ? 'User Invoice' : 'Lab Invoice'}</td>

                              <td>{items.total ? items.invoice_type == 'user_invoice' ?  items.total  : items.total - items.coupon_amount   : '0'}</td>
                              <td>0</td>
                              {/* <td>{items.total ? items.total :'0'}</td> */}
                              <td>Success</td>
                              <td>{moment(items.created_at).format("YYYY-MM-DD")}</td>
                              <td>
                                <a
                                  onClick={(e) => handleAppointment(items)}
                                  class="btn btn-light-secondary btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View"
                                  placeholder="View Invoice"
                                >
                                  <i class="far fa-eye"></i>
                                </a>

                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </Dashboard_layout>
      </>
    </div>
  );
};

export default Invoice;
